import { Link } from "react-router-dom";
import TMDBLogo from "../assets/images/tmdblogo.svg";

export const Footer = () => {
    return (
        <footer className="bg-white shadow mt-4 dark:bg-gray-800">
            <div className="px-4">
                <div className="w-full mx-auto max-w-screen-xl pt-4 md:flex md:items-center md:justify-between border-b-2 border-gray-500">
                    <span className="text-sm text-gray-500 sm:text-center dark:text-gray-400">
                        © 2023{" "}
                        <Link to="/" className="hover:underline">
                            CineMate
                        </Link>
                        . All Rights Reserved.
                    </span>
                    <ul className="flex flex-wrap items-center mt-3 text-sm font-medium text-gray-500 dark:text-gray-400 sm:mt-0">
                        <li>
                            <a href="/" target="_blank" rel="noreferrer" className="mr-4 hover:underline md:mr-6 ">
                                Instagram
                            </a>
                        </li>
                        <li>
                            <a href="/" target="_blank" rel="noreferrer" className="mr-4 hover:underline md:mr-6">
                            Linked In
                            </a>
                        </li>
                        <li>
                            <a href="/" target="_blank" rel="noreferrer" className="mr-4 hover:underline md:mr-6">
                                Youtube
                            </a>
                        </li>
                        <li>
                            <a href="/" target="_blank" rel="noreferrer" className="hover:underline">
                                Github
                            </a>
                        </li>
                    </ul>
                </div>
                <div className="w-full mx-auto max-w-screen-xl pt-4 pb-4 justify-start md:flex md:items-center md:justify-between">
                    <img src={TMDBLogo} className="h-3 mr-2" alt="TMDB Logo"/>
                    <span className="text-sm text-gray-500 sm:text-center dark:text-gray-400">
                        This website uses TMDB and the TMDB APIs but is not endorsed, certified, or otherwise approved by TMDB.
                    </span>
                </div>
            </div>
        </footer>
    );
};
