import { Card } from "../components"
import { useFetch } from "../hooks/useFetch";
import { useSearchParams } from "react-router-dom"; 
import { useTitle } from "../hooks/useTitle";

export const Search = ({apiPath}) => {
  const [searchParams] = useSearchParams();
  const queryTerm = searchParams.get('q');
  const {data: movies} = useFetch(apiPath, queryTerm);

  useTitle(`Search: ${queryTerm}`);
  
  return (
    <main>
        <section className="p-7 text-left">
          <p className="text-3xl text-gray-700 dark:text-white">{movies.length === 0 ? `No results found for: ${queryTerm}`:`Results for: ${queryTerm}` }</p>
        </section>
      <section className="max-w-7xl mx-auto py-7">
      <div className="flex justify-center flex-wrap ">
          {/* Movie Card */}
          { movies.map((movie) => (
            <Card key={movie.id} movie={movie}/>
          ))}     

        </div>
      </section>
    </main>
  )
}
